import { useEffect, useState } from 'react';

import { InputSimplesContainer, InputContent, ErrorField } from "./styles";

import { ReactComponent as ViewIcon } from 'src/client/assets/icons/view.svg';
import { ReactComponent as NotViewIcon } from 'src/client/assets/icons/notview.svg';
import { GenerateId } from 'src/client/hooks/identifiers';

type InputTypes = {
  style?: any;
  value?: string;
  type?: string;
  defaultValue?: string;
  placeholder: string;
  error?: string;
  refs?: any;
  required?: boolean;
  disabled?: boolean;
  onChange: any;
  className?: string;
  icon?: any;
  minLength?: number;
  maxLength?: number;
  textTransform?: "normal" | "capitalize" | "uppercase" | "lowercase";
}

const Input = ({ style, textTransform, value, type: initialType = 'text', defaultValue, placeholder, error, refs, required, disabled, onChange, className, icon, minLength, maxLength }: InputTypes) => {
  const [viewPassword, setViewPassword] = useState(false);
  const [type, setType] = useState(initialType);

  useEffect(() => {
    if(className === "password") setType(viewPassword ? 'text' : 'password')
  }, [viewPassword])

  const id = GenerateId();

  return (
    <InputSimplesContainer style={style} className={`${className}${error ? ' error' : ''}`}>
      {icon || ''}
      <InputContent className={className} error={error ? true : undefined} $icon={!icon ? 'noIcon' : ''} textTransform={textTransform}>
        <input type={type} minLength={minLength} autoComplete="off" maxLength={maxLength} id={id} ref={refs} defaultValue={defaultValue} value={value} className="_field" placeholder={placeholder} disabled={disabled} onChange={onChange} />
        { placeholder && <label htmlFor={id} className="_label">{placeholder}</label> }
        { className === "password" && (viewPassword ? <ViewIcon className="view-icon" onClick={() => setViewPassword(false)} /> : <NotViewIcon className="view-icon" onClick={() => setViewPassword(true)} />) }
      </InputContent>
      <ErrorField state={error ? true : undefined}>{error}</ErrorField>
    </InputSimplesContainer>
  )
}

export default Input;