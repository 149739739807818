import { styled } from "styled-components"
import { useContext } from "react";

import { Container } from "../styles/layout";
import { ReactComponent as MaintenanceSVG } from "../assets/illustrations/maintenance.svg";
import { WrapperInstitutional } from "../styles/admin";
import { ApplicationContext } from "../contexts/ApplicationContext";

const Content = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;

  svg {
    border-radius: 40px;
    border: 2px solid #ffffff;
    padding: 20px 20px 0;
    max-width: 380px;
    margin: 0 0 60px;
  }

  h1 {
    margin: 0 0 20px;
  }
`;

export default function MaintenanceSection() {

  const { scroll, setScroll } = useContext(ApplicationContext);

  return <WrapperInstitutional $scroll={scroll}>
    <Container>
      <Content>
        <MaintenanceSVG />
        <h1>Estamos aperfeiçoando os recursos para sua conta!</h1>
        <p>Essa página estará disponível em breve.</p>
      </Content>
    </Container>
  </WrapperInstitutional>
}