import { CategoryTitle } from "../styles/layout";
import { ReactComponent as GrupoDeOuvidosIcon } from "src/client/assets/icon.svg";
import { ReactComponent as SaturniaIcon } from "src/client/assets/icon-saturnia.svg";
import { ReactComponent as MultiphonoIcon } from "src/client/assets/icon-multiphono.svg";
import { DashboardOption, DashboardOptions } from "../styles/admin";

export default function LinksUteis(){
  return <>
    <CategoryTitle>Links úteis</CategoryTitle>

    <DashboardOptions>
      <DashboardOption onClick={() => window.open("https://site.grupodeouvidos.com.br")}>
        <GrupoDeOuvidosIcon />
        <div className="info">
          <h2>Nosso site</h2>
          <p>Acesso direto ao nosso site</p>
        </div>
      </DashboardOption>

      <DashboardOption onClick={() => window.open("https://grupodeouvidos.multiphono.com.br/web")}>
        <MultiphonoIcon />
        <div className="info">
          <h2>Multiphono</h2>
          <p>Acesso direto ao ERP</p>
        </div>
      </DashboardOption>

      <DashboardOption onClick={() => window.open("https://wa.me/5513997742436?text=Olá, preciso de ajuda com o sistema do GrupoDêOuvidos.")}>
        <SaturniaIcon style={{ width: '10px', height: '40px', fill: '#5d5d5d' }} /> 
        <div className="info">
          <h2>Suporte</h2>
          <p>Precisa de ajuda?</p>
        </div>
      </DashboardOption>
    </DashboardOptions>
  </>
}