import moment from "moment";
import { useContext } from "react";

import { DashboardOption, DashboardOptions, Wrapper } from "src/client/styles/admin";
import { CategoryTitle, Container } from "src/client/styles/layout";
import { ApplicationContext } from "src/client/contexts/ApplicationContext";
import LinksUteis from "src/client/components/LinksUteis";

import { ReactComponent as CompromissosSvg } from "src/client/assets/illustrations/reminders.svg";
import { ReactComponent as MessagesSvg } from "src/client/assets/illustrations/conversations.svg";
import { ReactComponent as ClientsSvg } from "src/client/assets/illustrations/clients.svg";
import { AdminContext } from "src/client/contexts/AdminContext";
import useRouter from "src/client/hooks/useRouter";

export default function AdminHomePage()
{
	const { user, scroll, setScroll, setConversations } = useContext(ApplicationContext);
	const { stats } = useContext(AdminContext);
	
	const router = useRouter();

	function getRegards()
	{
		const date = moment();
		const hour = date.hour();
		
		if(hour >= 0 && hour < 12) return "Bom dia";
		if(hour >= 12 && hour < 18) return "Boa tarde";
		if(hour >= 18 && hour < 24) return "Boa noite";

		return "Olá";
	}

	return <Wrapper $scroll={scroll} onScroll={(e: any) => setScroll(e.target.scrollTop)}>
		<Container>
			<h1>{getRegards()}, {user?.name?.split(" ")[0]}!</h1>

			<CategoryTitle>Visão geral</CategoryTitle>
			
			<DashboardOptions>

				<DashboardOption $notification={stats?.clients} onClick={() => router("/admin/leads")}>
					<ClientsSvg />
					<div className="info">
						<h2>Leads</h2>
						<p>Potenciais clientes</p>
					</div>
				</DashboardOption>

				<DashboardOption $notification={stats?.messages} onClick={() => setConversations({ visible: true, section: "upgrade" })}>
					<MessagesSvg />
					<div className="info">
						<h2>Atendimento</h2>
						<p>Conversas em tempo real</p>
					</div>
				</DashboardOption>

				<DashboardOption $notification={stats?.compromissos} onClick={() => setConversations({ visible: true, section: "upgrade" })}>
					<CompromissosSvg style={{ padding: 2 }} />
					<div className="info">
						<h2>Compromissos</h2>
						<p>Organize eventos internos</p>
					</div>
				</DashboardOption>

			</DashboardOptions>

			<LinksUteis />

		</Container>
	</Wrapper>
}