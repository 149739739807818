import { useContext, useEffect, useState } from "react";
import moment from "moment";
import 'moment/locale/pt-br';

import { ApplicationContext } from "src/client/contexts/ApplicationContext";
import { CategoryTitle, Container } from "src/client/styles/layout";
import { Wrapper, List, ListEmpty, Item, WrapperPagination } from "src/client/styles/admin";
import LoadingMoreItems from "src/client/components/Loading/MoreItems";
import actions from "src/client/actions";
import { ReactComponent as EyeIconSvg } from "src/client/assets/icons/eye.svg";
import { ILead } from "src/core/interfaces/ILead";
import SearchInput, { SearchFiltroOpcao, SearchFiltros } from "src/client/components/Inputs/search";
import { formatFullName } from "src/core/helpers/format";

// Tipagem para a resposta da API paginada
interface PaginatedLeads {
	leads: ILead[];
	totalLeads: number;
	totalPages: number;
	currentPage: number;
}

export default function AdminLeadsPage() {
	const [list, setList] = useState<ILead[]>([]);
	const [search, setSearch] = useState<string>(""); // Estado para o termo de pesquisa
	const [filters, setFilters] = useState<string[]>([]); // Filtros da pesquisa
	const [page, setPage] = useState<number>(1); // Estado para a página atual
	const [totalPages, setTotalPages] = useState<number>(0); // Estado para o total de páginas
	const [loadingMoreItems, setLoadingMoreItems] = useState(true);
	
	const { setLoading, scroll, setScroll, setConversations } = useContext(ApplicationContext);
	
	// Função para buscar os leads com pesquisa e paginação
	async function getLeadsData() {
		setLoading(true);
		try {
			const response: PaginatedLeads | null = await actions.api.get("/leads", {
				params: { search, page, filters }
			}).then((res) => res.data);
			
			if (response) {
				setList(response.leads);
				setTotalPages(response.totalPages);
			}
		} catch (error) {
			console.error("Erro ao buscar leads:", error);
		} finally {
			setLoadingMoreItems(false);
			setLoading(false);
		}
	}
	
	// Chamada para obter leads na montagem do componente e quando search/page mudar
	useEffect(() => {
		getLeadsData();
	}, [search, page, filters]);
	
	// Função para lidar com mudanças na busca
	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
		setPage(1); // Resetar para a primeira página ao fazer uma nova pesquisa
	};
	
	function updateFilters(newValue: string) {
		if (filters?.includes(newValue)) {
			// Remove o valor se ele já existir
			setFilters(filters.filter(f => f !== newValue));
		} else {
			// Adiciona o valor se ele não existir
			setFilters([ ...filters, newValue ]);
		}
	}
	
	return (
		<Wrapper $scroll={scroll} onScroll={(e: any) => setScroll(e.target.scrollTop)}>
		<Container>
		<CategoryTitle style={{ marginTop: 0 }}>Leads</CategoryTitle>
		
		{/* Campo de Pesquisa */}
		<SearchInput search={search} handleSearchChange={handleSearchChange}>
		<SearchFiltros>
		<SearchFiltroOpcao htmlFor="whatsapp">
		<input id="whatsapp" type="checkbox" onChange={ev => updateFilters("whatsapp")} defaultChecked={!!(filters?.filter(f => f === "whatsapp")[0])} />
		<span>WhatsApp</span>
		</SearchFiltroOpcao>
		<SearchFiltroOpcao htmlFor="site">
		<input id="site" type="checkbox" onChange={ev => updateFilters("site")} defaultChecked={!!(filters?.filter(f => f === "site")[0])} />
		<span>Site</span>
		</SearchFiltroOpcao>
		</SearchFiltros>
		</SearchInput>
		
		{list?.length > 0 ? (
			<List>
			<Item className="head">
			<div className="data">
			<span>Nome</span>
			</div>
			<div className="data">
			<span>Origem</span>
			</div>
			<div className="data">
			<span>Entrou em</span>
			</div>
			<div className="actions">
			<span>Ações</span>
			</div>
			</Item>
			{list.map((item: ILead, i: any) => (
				<Client key={i} data={item} setConversations={setConversations} />
			))}
			</List>
		) : loadingMoreItems ? (
			<LoadingMoreItems />
		) : (
			<ListEmpty>
			<h4>Nenhum lead encontrado.</h4>
			</ListEmpty>
		)}
		
		{/* Paginação */}
		<Pagination page={page} totalPages={totalPages} setPage={setPage} />
		</Container>
		</Wrapper>
	);
}

// Componente para um lead individual
const Client = ({ data, setConversations }: { data: ILead, setConversations(data: any): any }) => {
	moment.locale("pt-br");
	
	return (
		<Item onClick={() => setConversations({ visible: true, section: "client", data })}>
		<div className="data">
		<span><span>{formatFullName(data?.name)}</span></span>
		</div>
		<div className="data">
		<span className="tag">{(data?.origin || "Desconhecido")?.toLowerCase()}</span>
		</div>
		<div className="data">
		<span><span>{moment(data?.createdAt).calendar()}</span></span>
		</div>
		<div className="actions">
		<button>
		<EyeIconSvg />
		</button>
		</div>
		</Item>
	);
};

// Componente de Paginação
interface PaginationProps {
	page: number;
	totalPages: number;
	setPage: (page: number) => void;
}

const Pagination = ({ page, totalPages, setPage }: PaginationProps) => {
	return (
		<WrapperPagination>
		<button disabled={page === 1} onClick={() => setPage(page - 1)}>
		Anterior
		</button>
		<span style={{ margin: "0 10px" }}>
		Página {page} de {totalPages}
		</span>
		<button disabled={page === totalPages} onClick={() => setPage(page + 1)}>
		Próxima
		</button>
		</WrapperPagination>
	);
};
