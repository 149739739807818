import React from "react";
import { styled } from "styled-components";

const WindowModal = styled.div<{ $active: number }>`
  position: fixed;
  z-index: 1001;
  bottom: ${({ $active }) => $active === 1 ? '0vh' : '-100vh'};
  left: 0;

  transition: ${({ $active }) => $active === 1 ? 'bottom 300ms ease-in-out, 300ms background-color 100ms ease-in-out' : 'bottom 0ms ease-in-out, 0ms background-color 0s ease-in-out'};
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: 100vw;
  height: 100%;
  background-color: ${({ $active }) => $active === 1 ? 'rgba(118,118,118,.3)' : 'transparent'};
  backdrop-filter: ${({ $active }) => $active === 1 ? 'blur(10px)' : 'none'};

  > div > * {
    transition: ${({ $active }) => $active === 1 ? '200ms ease-in-out .2s' : '100ms ease-in-out 0ms'};
    opacity: ${({ $active }) => $active === 1 ? '1' : '0'};
  }
`;

const WindowBox = styled.div<{ $maxWidth?: string, $maxHeight?: string }>`
  width: 600px;
  min-width: 500px;
  max-width: ${({ $maxWidth }) => $maxWidth || "90vw"};
  
  height: 570px;
  min-height: 570px;
  max-height: ${({ $maxHeight }) => $maxHeight || "90vh"};

  overflow: hidden;
  resize: auto;
  position: relative;

  margin: 0 40px;
  background: #fff;
  border-radius: 8px;

  .CategoryTitle {
    &::after {
      display: none;
    }
  }

  .UploadWrapper {
    padding: 0;
    margin: 30px 0 0;
  }

  @media (max-width: 576px) {
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;

    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
    
    margin: 0;
    border-radius: 0;
  }
`;

const Header = styled.div<{ $hastitle: number }>`
  height: ${({ $hastitle }) => $hastitle === 1 ? "60px" : "0"};
  z-index: 2;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  
  .title {
    height: 100%;
    width: 100%;
    
    display: flex;
    align-items: center;
    
    font-weight: 800;
    color: rgba(120, 120, 120, 1);
    
    padding: 10px 30px;
    border-bottom: 1px solid var(--color-border);
  }
  
  .close {
    --size: 40px;
    border-radius: 100%;
    width: var(--size);
    height: var(--size);
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: white;
    border: 1px solid transparent;
    
    cursor: pointer;
    transition: 200ms ease-in-out;

    &::before, &::after {
      content: "";
      width: 50%;
      height: 4px;
      background: rgba(135, 135, 135, 1);
      border-radius: 10px;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      color: var(--color);
      background: var(--color-hover);
      border-color: var(--color-border);

      &::before, &::after {
        background: var(--color-primary);
      }
    }
  }
`;

const Content = styled.div`
  padding: 40px;
  overflow: auto;
  position: relative;
  height: 100%;

  @media (max-width: 576px) {
    height: 100%;
  }

  @media (min-width: 577px) {
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-width: 4px;
    }
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

type WindowTypes = {
  title?: string;
  maxWidth?: string;
  maxHeight?: string;
  status: boolean;
  close: Function;
  children: React.ReactNode;
}

export default function Windows({ title, maxWidth, maxHeight, status, close, children }: WindowTypes) {
  return <WindowModal $active={status ? 1 : 0}>
    <WindowBox $maxWidth={maxWidth} $maxHeight={maxHeight}>
      <Header $hastitle={title?.length ? 1 : 0}>
        {title ? <div className="title">{title}</div> : <></>} 
        <div className="close" onClick={() => close()} />
      </Header>
      <Content>{children}</Content>
    </WindowBox>
  </WindowModal>
}