import { ILead } from "../../interfaces/ILead";
import senderNamesBlackList from "./blocked-senders/names.json";
import senderEmailsBlackList from "./blocked-senders/emails.json";
import { formatFullName } from "../format";

export function LeadValidation(lead: ILead): ILead
{
	if(!lead) throw { error: "Você precisa enviar as informações do novo lead." };
	
	// NOME
	if(!lead.name || lead.name?.length < 1) throw { name: "Obrigatório" };

	lead.name = formatFullName(lead.name);
	
	// TELEFONE
	lead.phone = phoneValidator(lead.phone);

	// WHATSAPP ATÉ AQUI
	if (lead.origin && lead.origin.toLowerCase() == "whatsapp") return lead;

	// EMAIL
	emailValidator(lead.email);
	
	// MENSAGEM
	if(!lead.message || lead.message?.length < 1) throw { message: "Obrigatório" };
	if(lead.message?.length < 8) throw { message: "Mensagem muito curta" };

	return lead;
}

export function VerificarLeadsBloqueados(lead: ILead)
{
    // BLOCKED LIST
    if (lead.name && senderNamesBlackList.some((x: any) => x.toLowerCase() === lead.name.toLowerCase()))
    {
        throw { error: "Lead bloqueado!" };
    }

    // Verifica se "email" existe antes de fazer a comparação
    if (lead.email && senderEmailsBlackList.some((x: any) => {
        const email = lead.email.toLowerCase();
        return email === x || email.endsWith("@" + x);
    })) {
        throw { error: "Lead bloqueado!" };
    }
}

export function phoneValidator(phone: string)
{
	// TELEFONE
	if(!phone) throw { phone: "Obrigatório" };
	if(phone?.length < 8) throw { phone: "Número inválido" };

	return phone.replace(/\D/g, '');
}

export function emailValidator(email: string)
{
	if(!email || email?.length < 1) throw { email: "Obrigatório" };
	if(!email?.includes("@") || !email?.includes(".")) throw { email: "E-mail inválido" };
}