import { styled } from "styled-components";
import { Container, Button } from "src/client/styles/layout";
import { useNavigate } from "react-router-dom";
import MainImage from "src/client/assets/images/component-exams-otoscope.png";
import CheckIcon from "src/client/assets/icons/check-colored-green.svg";

const Wrapper = styled.div`
	padding: 80px 0;
	background: var(--color-white);
	min-height: 100vh;

	display: flex;
	align-items: center;
	position: relative;

	@media (max-width: 991px) {
		min-height: auto;
		flex-direction: column;
		padding: 20px 0 40px;
	}
`;

const WrapperImage = styled.picture`
	position: absolute;
	left: 0;
	height: 100vh;

	img {
		height: 100%;
	}

	@media (max-width: 1600px) {
		height: auto;
		max-width: 50vw;

		img {
			height: initial;
			max-width: 100%;
		}
	}

	@media (max-width: 1080px) {
		max-width: 40vw;
	}

	@media (max-width: 991px) {
		display: none;
	}
`;

const WrapperText = styled.div`
	max-width: 600px;
	margin: 0 0 0 auto;

	h2 {
		font-size: 3.2rem;
		color: var(--color);
		max-width: 560px;

		span {
			font-weight: 800;
			color: var(--color-primary);
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 40px 0;

		li {
			display: flex;
			align-items: center;
			font-size: 1.4rem;
			font-weight: 700;
			color: var(--color);
			margin-bottom: 16px;
			text-align: left;

			img {
				margin-right: 15px;
				
				--size: 25px;
				height: var(--size);
				max-width: var(--size);
				min-width: var(--size);
			}
		}
	}

	Button {
		width: 100%;
		max-width: 100%;
	}

	@media (max-width: 1400px) {
		max-width: 540px;

		h2 {
			font-size: 2.8rem;
		}
	}

	@media (max-width: 1300px) {
		max-width: 460px;
		margin-right: 100px;

		h2 {
			font-size: 2.4rem;
		}
	}
	
	@media (max-width: 1250px) {
		margin-right: 50px;
	}

	@media (max-width: 992px) {
		text-align: center;
		margin: 0 auto;

		ul {
			max-width: max-content;
			margin: 40px auto;
		}

		h2 {
			max-width: 600px;
			font-size: 2.2rem;
		}
	}

	@media (max-width: 576px) {

		ul {
			li {
				font-size: 1.2rem;
			}
		}

		h2 {
			max-width: 500px;
			font-size: 2rem;
		}

		Button {
			max-width: 360px;
			margin: 0 auto;
		}
	}

	@media (max-width: 480px) {
		h2 {
			font-size: 1.6rem;
			max-width: 340px;
			margin: 0 auto;
		}
	}
`;

const ExamsWrapper = styled.div`
	background-color: #0A817210;
	box-shadow: 0 0 40px #0A817240;
	border: 2px solid var(--color-primary-light);
	border-radius: 16px;
	margin: 60px 0 40px;

	> * {
		padding: 20px;
	}

	h3 {
		font-size: 1.5rem;
		font-weight: 800;
		color: var(--color-primary-light);
		margin-bottom: 20px;
		text-align: center;
		border-bottom: 2px solid var(--color-primary-light);
	}

	div {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		font-size: 1.2rem;
		padding-left: 30px;
		padding-right: 30px;
		gap: 20px;

		ul {
			margin: 0;
		}
	}

	@media (max-width: 1400px) {
		ul {

			li {
				font-size: 1.2rem;
			}
		}
	}

	@media (max-width: 1300px) {

		h3 {
			font-size: 1.3rem;
		}

		ul {
			li {
				font-size: 1.1rem;
			}
		}
	}

	@media (max-width: 576px) {
		max-width: 360px;
		margin: 0 auto 40px;
	
		h3 {
			margin-bottom: 10px;
			font-size: 1rem;
		}

		> div {
			grid-template-columns: 1fr;
			gap: 0;
		}

		ul {
			padding: 0;

			li {
				font-size: 1.1rem;
				margin-bottom: 10px;
			}
		}

	}
`;

export default function HomeSectionExames()
{
	const navigate = useNavigate();

	return <Wrapper>
		<WrapperImage>
			<img src={MainImage} alt="Exames Audiológicos - Grupo Dê Ouvidos" />
		</WrapperImage>
		<Container>
			<WrapperText>
				<h2>Conheça nossos exames audiológicos e <span>se reconecte com a vida!</span></h2>
				<ul>
					<li><img src={CheckIcon} alt="Check" /> Exames completos, resultado na hora!</li>
					<li><img src={CheckIcon} alt="Check" /> Atendimento ágil e eficiente</li>
					<li><img src={CheckIcon} alt="Check" /> Acompanhamento especializado</li>
				</ul>
				<ExamsWrapper>
					<h3>EXAMES MAIS PROCURADOS</h3>
					<div>
						<ul>
							<li>Audiometria</li>
							<li>Impedanciometria</li>
							<li>Reabilitação Labiríntica</li>
						</ul>
						<ul>
							<li>Eletrococleografia</li>
							<li>Teste da Orelhinha</li>
							<li>E muito mais...</li>
						</ul>
					</div>
				</ExamsWrapper>
				<Button onClick={() => navigate("/exames-audiologicos")}>Ver Todos os Exames</Button>
			</WrapperText>
		</Container>
	</Wrapper>
}