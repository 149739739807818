const linkNames = ["da", "de", "di", "do", "du", "das", "dos"];

export function formatFullNameAbbr(name?: string | null): string {
  if (!name) return "";

  const nameSplited = name.trim().split(" ");
  let userName = "";


  nameSplited.forEach((namePart: string, index: number) => {
    // Primeira palavra e última (nome e sobrenome) sem abreviação
    if (index === 0 || index === nameSplited.length - 1) {
      userName += ` ${namePart}`;
    }
    // Verifica se o nome do meio é um "nome de ligação"
    else if (!linkNames.includes(namePart.toLowerCase())) {
      userName += ` ${namePart[0]}.`; // Abrevia os nomes do meio
    } else {
      userName += ` ${namePart}`; // Mantém os nomes de ligação
    }
  });

  return userName.trim(); // Remove espaços extras no início e fim
}

export function formatFullName(name?: string | null): string {
  if (!name) return "";

  const nameSplited = name.trim().split(" ");
  let userName = "";

  nameSplited.forEach((namePart: string, index: number) => {
    // Capitaliza o nome, exceto se for um nome de ligação
    if (!linkNames.includes(namePart.toLowerCase()) || index === 0 || index === nameSplited.length - 1) {
      userName += ` ${capitalize(namePart)}`;
    } else {
      userName += ` ${namePart.toLowerCase()}`; // Nomes de ligação permanecem em minúsculas
    }
  });

  return userName.trim();
}

// Função auxiliar para capitalizar
function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}