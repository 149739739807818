import styled from "styled-components";
import actions from "src/client/actions";

import { Container } from "src/client/styles/layout";
import { FooterWrapper, FooterContent, FooterSection, Units, FooterBottom } from "./styles";

import LogoWhite from "src/client/assets/logo-white.png";
import { ReactComponent as PhoneIcon } from "src/client/assets/icons/phone-light.svg";
import { ReactComponent as FacebookIcon } from "src/client/assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "src/client/assets/icons/instagram.svg";
import { ReactComponent as YouTubeIcon } from "src/client/assets/icons/youtube.svg";
import { ReactComponent as WhatsAppIcon } from "src/client/assets/icons/whatsapp.svg";
import { ReactComponent as EmailIcon } from "src/client/assets/icons/mail.svg";
import { ReactComponent as SaturniaLogo } from "src/client/assets/logo-saturnia.svg";

export default function Footer() {

	return (
		<FooterWrapper>
			<FooterContent>
				<Container className="container">
					<Units>
						<div className="unit">
							<div className="title">
								<h4>São Bernardo do Campo, SP</h4>
								<h5>UNIDADE I - Centro</h5>
							</div>
							<div className="location">
								<p>Rua Jurubatuba, 1350</p>
								<p>Sala 04 (Térreo)</p>
								<p>CEP: 09725-000 - SP</p>
							</div>
							<div className="phone">
								<button onClick={() => actions.phoneSend("551135364554")}><PhoneIcon /> (11) 3536-4554</button>
								<button onClick={() => actions.whatsAppSend("5511992449217")}><WhatsAppIcon /> (11) 99244-9217</button>
							</div>
						</div>
						<div className="unit">
							<div className="title">
								<h4>Penha de França - SP</h4>
								<h5>UNIDADE II</h5>
							</div>
							<div className="location">
								<p>Rua General Sócrates, 216</p>
								<p>Salas 97 e 98</p>
								<p>CEP: 03632-040 - SP</p>
							</div>
							<div className="phone">
								<button onClick={() => actions.phoneSend("551145027900")}><PhoneIcon /> (11) 4502-7900</button>
								<button onClick={() => actions.whatsAppSend("5511939511631")}><WhatsAppIcon /> (11) 93951-1631</button>
							</div>
						</div>
						<div className="unit">
							<div className="title">
								<h4>Moema - São Paulo</h4>
								<h5>UNIDADE III</h5>
							</div>
							<div className="location">
								<p>Avenida Miruna, 162</p>
								<p>CEP: 04084-000 - SP</p>
							</div>
							<div className="phone">
								<button onClick={() => actions.phoneSend("551123377908")}><PhoneIcon /> (11) 2337-7908</button>
								<button onClick={() => actions.whatsAppSend("5511994880122")}><WhatsAppIcon /> (11) 99488-0122</button>
							</div>
						</div>
						<div className="unit">
							<div className="title">
								<h4>Lapa - São Paulo</h4>
								<h5>UNIDADE IV</h5>
							</div>
							<div className="location">
								<p>Rua Félix Guilhem, 30</p>
								<p>CEP: 05061-250 - SP</p>
							</div>
							<div className="phone">
								<button onClick={() => actions.phoneSend("551130232026")}><PhoneIcon /> (11) 3023-2026</button>
								<button onClick={() => actions.whatsAppSend("5511939523336")}><WhatsAppIcon /> (11) 93952-3336</button>
							</div>
						</div>
					</Units>

					<img src={LogoWhite} className="logo" />

					<FooterSection className="info-section">
						<h4>Grupo Dê Ouvidos</h4>
						<p>Fono & Audio Quality</p>
						<p>03.920.520/0001-00</p>
						<p>contato@grupodeouvidos.com.br</p>
						<div className="contact">
							<button className="stroke" onClick={() => actions.emailSend()}><EmailIcon /></button>
							<button onClick={() => actions.whatsAppSend()}><WhatsAppIcon /></button>
							<a href="https://instagram.com/grupodeouvidos"><InstagramIcon /></a>
							<a href="https://facebook.com/deouvidos" className="stroke"><FacebookIcon /></a>
							<a href="https://www.youtube.com/channel/UCQvPmcqI8rkxp9MAFm59-Bg" className="stroke"><YouTubeIcon /></a>
						</div>
					</FooterSection>
					
					<FooterSection className="links-section">
						<h4>Links úteis</h4>
						<a href="/quem-somos">Quem somos</a>
						<a href="/depoimentos">Depoimentos</a>
						<a href="/exames-audiologicos">Exames audiológicos</a>
						<a href="/aparelhos-auditivos">Aparelhos auditivos</a>
						<a href="/contato?subject=TrabalheConosco">Trabalhe conosco</a>
						<a href="/admin">Administração</a>
						<a href="/contato">Entre em contato</a>
					</FooterSection>

				</Container>
			</FooterContent>
			<FooterBottom>
				<Container className="container">
					<p>Copyright © 2024 Grupo Dê Ouvidos. Todos os direitos reservados.</p>
					<p>Desenvolvido por <a href="https://saturniatecnologia.com.br" target="_blank"><SaturniaLogo className="logo-saturnia"/></a></p>
				</Container>
			</FooterBottom>
		</FooterWrapper>
  	);
}
