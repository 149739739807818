import { styled } from "styled-components";
import { ReactComponent as WhatsAppIcon } from "src/client/assets/icons/whatsapp.svg";
import actions from "src/client/actions";

const Wrapper = styled.button`
	background: var(--color-whatsapp);

	--size: 80px;
	--margin: 60px;

	width: var(--size);
	height: var(--size);

	position: fixed;
	right: var(--margin);
	bottom: var(--margin);
	border-radius: 100%;
	border: none;
	z-index: 998;

	cursor: pointer;

	svg {
		margin: 18px;
		fill: #FFF;

		path {
			fill: #FFF;
		}
	}

	&:hover {
		background: #1bb653;
	}

	@media (max-width: 1400px) {
		--size: 75px;
		--margin: 40px;
	}

	@media (max-width: 576px) {

		--size: 70px;
		--margin: 20px;
	}
`;

export default function WhatsAppButton()
{
	return <Wrapper onClick={() => actions.whatsAppSend()}>
		<WhatsAppIcon />
	</Wrapper>
}