import { styled } from "styled-components";
import { Container, Button } from "src/client/styles/layout";
import { useNavigate } from "react-router-dom";
import MainImage from "src/client/assets/images/component-aparelhos-auditivos.png";
import CheckIcon from "src/client/assets/icons/check-colored-green.svg";

const Wrapper = styled.div`
	padding: 80px 0;
	background: var(--color-white);
	min-height: 100vh;

	display: flex;
	align-items: center;
	position: relative;

	@media (max-width: 991px) {
		min-height: 1000px;
		flex-direction: column;
	}

	@media (max-width: 480px) {
		min-height: 920px;
	}

	@media (max-width: 576px) {
		min-height: 820px;
	}

	@media (max-width: 480px) {
		min-height: 720px;
	}

`;

const WrapperImage = styled.picture`
	position: absolute;
	right: 0;
	height: 100vh;

	img {
		height: 100%;
	}

	@media (max-width: 1600px) {
		height: auto;
		max-width: 60vw;

		img {
			height: initial;
			max-width: 100%;
		}
	}

	@media (max-width: 991px) {
		max-width: 100%;
		bottom: -150px;
	}
	
	@media (max-width: 860px) {
		bottom: -100px;
	}

	@media (max-width: 768px) {
		bottom: -60px;
	}

	@media (max-width: 600px) {
		bottom: 0px;
	}

	@media (max-width: 480px) {
        bottom: -40px;
	}

`;

const WrapperText = styled.div`
	max-width: 600px;
	margin: 0 auto 0 0;
	z-index: 1;
	position: relative;

	h2 {
		font-size: 2.8rem;
		color: var(--color);
		max-width: 460px;

		span {
			font-weight: 800;
			color: var(--color-primary);
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 60px 0;

		li {
			display: flex;
			align-items: center;
			font-size: 1.3rem;
			font-weight: 700;
			color: var(--color);
			margin-bottom: 20px;
			max-width: 400px;

			img {
				margin-right: 15px;
				
				--size: 25px;
				height: var(--size);
				max-width: var(--size);
				min-width: var(--size);
			}
		}
	}

	@media (max-width: 992px) {
		margin: 0 auto;
		
		h2 {
			text-align: center;
			margin: 0 auto;
		}

		ul {
			margin: 40px auto;
			padding: 0 20px;
			max-width: 500px;
		}

		Button {
			margin: 0 auto;
		}
	}

	@media (max-width: 576px) {
		max-width: 400px;

		ul {
			li {
				font-size: 1.2rem;
			}
		}

		h2 {
			max-width: 500px;
			font-size: 2rem;
		}

		Button {
			max-width: 360px;
			margin: 0 auto;
		}
	}

	@media (max-width: 480px) {
		h2 {
			font-size: 1.6rem;
			max-width: 300px;
			margin: 0 auto;
		}
	}
`;

export default function HomeSectionAparelhosAuditivos()
{
	const navigate = useNavigate();

	return <Wrapper>
		<Container>
			<WrapperText>
				<h2>Encontre o aparelho <span>perfeito para você!</span></h2>
				<ul>
					<li><img src={CheckIcon} alt="Check" /> Aparelhos auditivos que se ajustam às suas necessidades e estilo de vida</li>
					<li><img src={CheckIcon} alt="Check" /> Qualidade 100% garantida</li>
					<li><img src={CheckIcon} alt="Check" /> Financiamento em até 60x</li>
				</ul>
				<Button onClick={() => navigate("/orcamento")}>Quero receber orçamento</Button>
			</WrapperText>
		</Container>
		<WrapperImage>
			<img src={MainImage} alt="" />
		</WrapperImage>
	</Wrapper>
}