import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

  :root {
    --header-size: 100px;

    --color-primary: #0A8172;
    --color-primary-light: #00BDAC;
    
    --color: #002b25; // TEXT COLOR

    --color-orange: #EC910C;
    --color-orange-light: #F7B655;
    
    --color-whatsapp: #25D366;

    --color-white: #FFFFFF;
    --color-yellow: #FFD571;

    --color-background: #f3f3f3;
    --color-item: #fff;
    --color-hover: #e5f7f7;
    --color-border: #c1cece;
    --color-border-secondary: rgba(0,0,0,.1);
  }

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		outline: none;    
    -webkit-tap-highlight-color: transparent;
	}
  
  ::selection {
    color: #FFF;
    background: #21d9c9;
  }

  ::-moz-selection { /* Code for Firefox */
    color: #FFF;
    background: #21d9c9;
  }

  body {
    background: var(--color-white);
  }

  html, body {
    overflow: hidden;
  }

	body, input, textarea, button {
		font-family: "Lato", sans-serif;
		font-weight: 400;
    color: var(--color);
	}

	h1, h2, h3, h4 {
		line-height: 1.2;
	}

	h5, h6, p {
    line-height: 1.4;
	}

	p {
    font-size: 18px;
	}

	a {
    font-size: 20px;
    color: var(--color-primary-light);
    font-weight: 800;

		&:hover {
			text-decoration: none;
		}
  }

	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus, 
	input:-webkit-autofill:active{
		-webkit-box-shadow: none!important;
		background: transparent!important;
		outline: none!important;
		-webkit-text-fill-color: var(--color)!important;
		transition: background-color 5000s ease-in-out 0s;
	}

  @media (max-width: 576px) {
    p {
      font-size: 16px;
    }
  }
  
	@media (min-width: 577px) {
    ::-webkit-scrollbar {
      width: 14px;
      background: transparent;
      padding: 2px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 6px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border-radius: 6px;
      box-shadow: none;
      min-height: 50px;
      border: 4px solid transparent;
      background-color: rgba(0,0,0,.3);
      
      :hover {
        background-clip: padding-box;
        border-radius: 6px;
        box-shadow: none;
        min-height: 50px;

        border: 3px solid transparent;
        background-color: rgba(0,0,0,.5);
      }
    }
  }
`;

export const fakeLoad = `
  > * > * {
    opacity: 0;
  }
`;

export const Content = styled.div<{ $prepared: boolean }>`
  ${({ $prepared }) => !$prepared && fakeLoad};
`;