import DraDeboraCamposBattistin from "src/client/assets/images/professionals/Dra-Debora-Campos-Battistin.png";
import DraKellyDAmelioPedroso from "src/client/assets/images/professionals/Dra-Kelly-D-Amelio-Pedroso.png";
import DraLilianSanchesOliveira from "src/client/assets/images/professionals/Dra-Lilian-Sanches-Oliveira.png";
import DraJulianaStraiotodeSouza from "src/client/assets/images/professionals/Dra-Juliana-Straioto-de-Souza.png";
import DraVanessaCecarelli from "src/client/assets/images/professionals/Dra-Vanessa-Cecarelli.png";
import DraVitoria from "src/client/assets/images/professionals/Dra-Vitoria.png";
import DraMarianadoSantos from "src/client/assets/images/professionals/Dra-Mariana-do-Santos.png";
import DrBrenoRocha from "src/client/assets/images/professionals/Dr-Breno-Rocha.png";
import DraDayanaSantos from "src/client/assets/images/professionals/Dra-Dayana-Santos.png";

const professionals = [
    {
        name: "Dra. Débora Campos Battistin",
        image: DraDeboraCamposBattistin,
        paragraphs: [
            "Fonoaudióloga (CRFa 2-9038) formada pela PUC-SP, com especialização em audiologia clínica pelo CEFAC e mestrado em Fonoaudiologia pela PUC-SP.",
            "Ex-docente em audiologia clínica e hospitalar."
        ]
    },
    {
        name: "Dra. Kelly D’Amelio Pedroso",
        image: DraKellyDAmelioPedroso,
        paragraphs: [
            "Fonoaudióloga (CRFa 2–7001) formada pela PUC – SP desde 1993. Especialista em Otoneurologia pelo CFFa 9881/24. Especialista em Audiologia pelo CFFa 9882/24."
        ]
    },
    {
        name: "Dra. Lilian Sanches Oliveira",
        image: DraLilianSanchesOliveira,
        paragraphs: [
            "Fonoaudióloga (CRFa 2-4345) formada pela PUC-SP desde 1987, com mestrado em Saúde da Comunicação Humana e Eletrofisiologia da Audição pela Santa Casa de São Paulo."
        ]
    },
    {
        name: "Dra. Juliana Straioto de Souza",
        image: DraJulianaStraiotodeSouza,
        paragraphs: [
            "Fonoaudióloga (CRFa 2-9448) formada pela FOB-USP Bauru em 1998, especialista em Audiologia pelo Conselho Federal de Fonoaudiologia e pós-graduada em Avaliação e Reabilitação Vestibular pelo CEFAC/SP."
        ]
    },
    {
        name: "Dra. Vanessa Cecarelli",
        image: DraVanessaCecarelli,
        paragraphs: [
            "Fonoaudióloga (CRFa 2-9418) formada pelo Centro Universitário São Camilo desde 1998.",
            "Especialista em audiologia clínica pela Universidade de São Paulo - SP."
        ]
    },
    {
        name: "Dra. Vitória Arrivabene",
        image: DraVitoria,
        paragraphs: [
            "Fonoaudióloga (CRFa. 2-21374) formada pelo Centro Universitário das Faculdades Metropolitanas Unidas 2019. Pós graduanda em audiologia clínica pela SIGLA."
        ]
    },
    {
        name: "Dra. Mariana do Santos",
        image: DraMarianadoSantos,
        paragraphs: [
            "Fonoaudióloga (CRFa 2-11843-8) formada pela Universidade Federal de Minas Gerais (UFMG). Fellow em Implante Coclear e próteses auditivas implantáveis pelo Centrinho da Universidade de São Paulo (USP).",
            "Especialização em Eletrofisiologia da Audição.",
            "Experiência no diagnóstico audiológico avançado, triagem auditiva neonatal e reabilitação auditiva com aparelhos auditivos."
        ]
    },
    {
        name: "Dr. Breno Rocha",
        image: DrBrenoRocha,
        paragraphs: [
            "Fonoaudiólogo (CRFa 2-1477-9) formado pela Centro Universitario Fametro-AM Com especialização em Audiologia clínica e ocupacional pelo CEFAC-SP.",
            "Pós graduando em Saúde mental pela Escola Nacional de Saúde publica Sergio Arouca (ENSP/FIOCRUZ)."
        ]
    },
    {
        name: "Dra. Dayana Santos",
        image: DraDayanaSantos,
        paragraphs: [
            "Fonoaudióloga (CRFa 2-18270) formada pela Universidade Metodista de Paulo.",
            "Especialista em audiologia clínica pela Santa Casa. Atuação em triagem, diagnóstico e reabilitação auditiva."
        ]
    }
];

export default professionals;