import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 0 300px;
  width: 100%;
  overflow: auto;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
`;

export const ListItems = styled.div<{ $active: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px;
  display: ${props => !props.$active && 'none'};

  > label {
    font-size: 20px;
    font-weight: 400;
    margin: 20px 0;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  background: var(--color-item);
  border-radius: 10px;
  margin: 0 0 6px;
  border: 1px solid var(--color-border);

  transition: .4s ease-in-out;

  @media (max-width: 576px) {
    padding: 8px;
  }

  .profile-image {
    --picture-size: 45px;
    height: var(--picture-size);
    min-width: var(--picture-size);
    max-width: var(--picture-size);
    border-radius: 100%;
    margin: 5px 20px 5px 5px;
    background-color: var(--color-primary);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    font-size: 18px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 576px) {
      --picture-size: 45px;
    }
  }

  .info {
    width: 100%;
    overflow: hidden;
    position: relative;

    :after {
      content: "";
      background: linear-gradient(to left, var(--color-item), transparent);
      width: 50px;
      height: 100%;

      position: absolute;
      top: 0;
      right: 0;
    }

    h3 {
      white-space: nowrap;
      font-size: 18px;
      font-weight: 800;
      margin: 0;
      display: flex;
    }
    
    span {
      font-size: 14px;
      font-weight: 400;
      display: flex;
    }

    .pending {
      padding: 4px 7px 3px;
      border-radius: 50px;
      font-size: 9px;
      font-weight: 800;
      margin: 0 6px;

      background: rgb(249 171 55 / 86%);
      color: rgb(50 30 0);
    }

    @media (max-width: 576px) {
      h3 {
        font-size: 16px;
        padding: 8px 0 0;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .actions {
    display: grid;
    grid-template-columns: repeat(2, 40px);
    grid-gap: 5px;
    margin: 0 15px 0 0;

    --action-size: 55px;
    --action-svg-size: 20px;

    .action {
      height: var(--action-size);
      min-width: var(--action-size);
      max-width: var(--action-size);
      border-radius: var(--action-size);
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        height: var(--action-svg-size);
        min-width: var(--action-svg-size);
        max-width: var(--action-svg-size);
        fill: var(--color);
        opacity: .4;
        transition: .2s ease-in-out;
      }

      &:hover svg {
        opacity: .8;
      }

    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(2, 30px);
      grid-gap: 10px;
      margin: 0 8px 0 0;
      
      .action {
        height: 24px;
        min-width: 24px;
        max-width: 24px;
      }
    }
  }
`;