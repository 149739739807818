import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "src/client/contexts/ApplicationContext";

import { Content, ContentItem } from "../styles";
import { Button } from "src/client/styles/layout";
import TextArea from "src/client/components/Inputs/textarea";
import CheckBox from "src/client/components/Inputs/checkbox";
import Input from "src/client/components/Inputs/official";
import InputDate from "src/client/components/Inputs/date";
import moment from "moment";
import { useSocket } from "src/client/contexts/SocketContext";
import { ICommitment } from "src/core/interfaces/IMessage";

type ComponentTypes = {
  active?: boolean;
}

export default function NewCompromisso({ active }: ComponentTypes) {

  const subjects = ["Reunião", "Audiência", "Outro"];

  const [subjectOption, setSubjectOption] = useState(subjects[0]);
  const [formState, setFormState] = useState<ICommitment>({});
  const [errors, setErrors] = useState(null) as any;

  const { conversations, setConversations } = useContext(ApplicationContext);
  const { sendNewCommitment } = useSocket();

  async function handleSubmitRequest() {
    if(subjectOption === "Outro" && (!formState?.subject || formState?.subject?.length <= 0)) return setErrors({ subject: "Obrigatório" });
    
    if(!formState?.description || formState?.description?.length <= 0) return setErrors({ description: "Obrigatório" });
    if(formState?.description?.length <= 10) return setErrors({ description: "Mensagem muito curta" });

    setConversations({ ...conversations, loading: { text: "Carregando..." } });

    var data: ICommitment = formState;
    data.date = formState.date;
    if(subjectOption !== "Outro") data.subject = subjectOption;
    if(!formState.date) data.date = new Date();

    sendNewCommitment(`***Novo compromisso***
      Título: **${data?.subject}**\n
      Data e hora: **${moment(data.date).format("DD/MM/YYYY")} às ${moment(data.date).format("HH:mm")}**\n
      *Informações adicionais:*
      ${formState?.description}\n\n
    `, data);

    // Default Data
    setSubjectOption(subjects[0]);
    setFormState({});
    setConversations({ visible: false });
  }

  useEffect(() => setErrors(null), [formState]);

  return <Content className={active ? "active" : ""}>
    <h4>Criar novo compromisso</h4>

    <ContentItem>
      <CheckBox options={subjects} selected={subjectOption} onChange={(newOption: string) => setSubjectOption(newOption)} />
      { subjectOption === "Outro" ? <Input style={{ marginTop: "15px" }} error={errors?.subject} placeholder="Título do compromisso" maxLength={40} value={formState?.subject} onChange={(e: any) => setFormState({ ...formState, subject: e.target.value })} /> : <></> }
    </ContentItem>

    <ContentItem>
      <h5>Data e hora</h5>
      <InputDate initialDate={new Date()} cb={(date) => setFormState({ ...formState, date })} />
    </ContentItem>

    <ContentItem>
      <h5>Informações adicionais</h5>
      <TextArea error={errors?.description} placeholder="Descrição" initialValue={formState?.description} onChange={(e: any) => setFormState({ ...formState, description: e.target.value })} limit={1000} />
    </ContentItem>

    <Button style={{ margin: "auto 0 0 auto"}} onClick={() => handleSubmitRequest()}>Confirmar</Button>
  </Content>
}