import { styled } from "styled-components";

import { ReactComponent as SearchIcon } from "src/client/assets/icons/search.svg";
import Input from "./official";

const Wrapper = styled.div`
    position: sticky;
    top: -80px;
    z-index: 998;
    margin: 0 0 20px;
    display: flex;
    overflow: hidden;
    background: white;
    border: 1px solid var(--color-border);
`;

export const SearchFiltros = styled.div`
	display: flex;
`;

export const SearchFiltroOpcao = styled.label`
	display: flex;
	align-items: center;
	padding: 0px 20px;
	cursor: pointer;

	user-select: none;
	-o-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;

	* {
		cursor: pointer;
	}

	input {
		width: 20px;
		height: 20px;
	}

	span {
		padding: 0 10px;
		font-weight: 700;
		color: rgba(0,0,0,.7);
	}

	&:hover {
		background: var(--color-hover);
	}
`;

export default function SearchInput({ search, handleSearchChange, children }: any) {

	return <Wrapper>
		<Input
			className="noBorder"
			icon={<SearchIcon />}
			type="text"
			placeholder="Pesquise por nome, telefone ou e-mail"
			value={search}
			onChange={handleSearchChange}
			/>
		
		{children}
	</Wrapper>
}