import { useContext, useState } from 'react';

import { Button, Container } from 'src/client/styles/layout';
import { ApplicationContext } from 'src/client/contexts/ApplicationContext';
import { Wrapper, Content } from './styles';
import actions from "src/client/actions";

import { ReactComponent as Logo } from 'src/client/assets/logo.svg';
import { ReactComponent as HomeBoldSvg } from "src/client/assets/icons/home-bold.svg";
import { ReactComponent as HomeLightSvg } from "src/client/assets/icons/home-light.svg";
import { ReactComponent as ConversationsIcon } from "src/client/assets/icons/conversations.svg";
import { ReactComponent as ConversationsBoldIcon } from "src/client/assets/icons/conversations-bold.svg";
import { ReactComponent as ClientsLightSvg } from "src/client/assets/icons/clients-light.svg";
import { ReactComponent as ClientsBoldSvg } from "src/client/assets/icons/clients-bold.svg";
import { ReactComponent as WhatsAppIcon } from "src/client/assets/icons/whatsapp.svg";

import useRouter from 'src/client/hooks/useRouter';
import usePathname from 'src/client/hooks/usePathname';
import Profile from '../Profile';

export default function Header({ title, isDashboard, className }: { title: string, isDashboard?: boolean, className?: string }) {
	
	const [menuMobileState, setMenuMobileState] = useState(false);
	
	const { prepared, scroll, isAuthenticated, isAdmin, user, notification, setConversations } = useContext(ApplicationContext);
	
	const router = useRouter();
	const pathname = usePathname();
	
	function logoClick()
	{
		if(isAuthenticated) return router("/admin");
		return router("/");
	}
	
	function getMenu()
	{
		if(isAuthenticated) return [
			{ icon: <HomeLightSvg />, iconSelected: <HomeBoldSvg />, title: "Visão Geral", new: false, pathname: "/admin", className: "just-icon" },
			{ icon: <ClientsLightSvg />, iconSelected: <ClientsBoldSvg />, title: "Leads", new: false, pathname: "/admin/leads", className: "" },
			{ icon: <ConversationsIcon />, iconSelected: <ConversationsBoldIcon />, title: "Atendimentos", new: notification?.messages, className: "", onClick: () => setConversations({ visible: true, section: "upgrade" }) },
		];
		
		return [
			{ icon: null, title: "Início", pathname: "/", className: "" },
			{ icon: null, title: "Quem Somos", pathname: "/quem-somos", className: "" },
			{ icon: null, title: "Exames", pathname: "/exames-audiologicos", className: "" },
			{ icon: null, title: "Aparelhos Auditivos", pathname: "/aparelhos-auditivos", className: "" },
			{ icon: null, title: "Contato", pathname: "/contato", className: "" },
			{ icon: null, title: <Button min={true} whatsapp={true} onClick={() => actions}><WhatsAppIcon /> WhatsApp</Button>, pathname: "/whatsapp", className: "goToMyAccount mobile" },
		];
	}
	
	function getActions()
	{
		if(!isAuthenticated) return <Button min={true} whatsapp={true} onClick={() => actions.whatsAppSend()} className="goToMyAccount"><WhatsAppIcon /> WhatsApp</Button>
		else return <Profile user={user} isAdmin={isAdmin} Logout={() => {}} closeMenuMobile={() => setMenuMobileState(false)} redirect={redirect} />
	}
	
	function redirect(redirectFn: () => void, showLoadingLine: boolean) {
		setMenuMobileState(false);
		redirectFn();
	}
	
	return <Wrapper id="header" $prepared={prepared} $scroll={scroll} className={className} $pagetype={((isDashboard || isAdmin) && isAuthenticated) ? "Dashboard" : "Institutional"}>
		<Container>
			<Content $prepared={prepared} $scroll={scroll} $active={menuMobileState ? true : undefined} $pagetype={((isDashboard || isAdmin) && isAuthenticated) ? "Dashboard" : "Institutional"}>
			<Logo className="logo" onClick={() => logoClick()} />
			
			<div className="menu">
					{ getMenu()?.map((item: any, i: number) => 
						(item.onClick || item.pathname) ? (
							<div key={i} onClick={item.onClick ? item.onClick : () => {
										if(pathname !== item.pathname) redirect(() => router(item.pathname), true)
										else setMenuMobileState(false);
									}} className={`button ${item?.className}${item.title === title ? ` active` : ""}`}>
							
								{item?.icon ? 
									<div className="icon">
										{(item.title === title ? item?.iconSelected : item.icon)}
										{item.new ? <div className="new"></div> : <></>}
									</div>
									: <></>
								}
								<span>{item.title}</span>
							</div>
						) : (
							<div key={i} onClick={() => redirect(item.onClick, true)} className={`button ${item?.className}${item.title === title ? ` active` : ""}`}>
								{item?.icon ? (item.title === title ? item?.iconSelected : item.icon) : ""}
								<span>{item.title}</span>
							</div>
						)
					)}
				</div>
				
				<div className="actions">{getActions()}</div>
				
				<div className="toogleMobile" onClick={() => setMenuMobileState(!menuMobileState)}>
				<div></div>
				<div></div>
				<div></div>
			</div>
			</Content>
		</Container>
	</Wrapper>
	}