import { styled } from "styled-components";
import { Button, Container } from "src/client/styles/layout";
import { useNavigate } from "react-router-dom";
import BackgroundImage from "src/client/assets/images/quem-somos-one-banner.jpg";

const Wrapper = styled.div`
  height: calc(100vh - var(--header-size));
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-light);
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  color: var(--white);

  position: relative;

  @media (max-width: 860px) {
    height: auto;
    padding: 60px 0;
  }

  
  @media (max-width: 576px) {
    padding: 80px 0 20px;
    background-position: center right;
  }

`;

const ContentWrapper = styled.div`
  text-align: left;
  z-index: 2;
  max-width: 640px;
  color: var(--color-white);

  h1 {
    font-size: 60px;
    line-height: 1.2;
    font-weight: 400;

    span {
      font-weight: 800;
    }
  }

  p {
    max-width: 80%;
    font-size: 26px;
    margin: 20px 0 40px;
  }

  Button {
    margin: 0 0 100px;
  }

	@media (max-width: 1400px) {
    max-width: 540px;

		h1 {
      font-size: 3rem;
		}
	}

  @media (max-width: 860px) {
    max-width: 300px;
    padding: 40px 0;
    
    h1 {
      font-size: 1.6rem;
    }
  }

  @media (max-width: 576px) {
    max-width: 220px;
    padding: 40px 0 0;
    
    h1 {
      font-size: 1.2rem;
    }
  }
`;

export default function QuemSomosSectionOne() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <h1>Nosso compromisso é <span>devolver a você a alegria de ouvir</span> com clareza os sons da vida.</h1>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
}