const NODE_ENV = process.env.NODE_ENV || 'development';
const IS_PRODUCTION = NODE_ENV === 'production';

// Servidor
const REACT_APP_SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN || 'localhost';
const REACT_APP_SERVER_PORT = process.env.REACT_APP_SERVER_PORT ? Number(process.env.REACT_APP_SERVER_PORT) : undefined;

// Cliente
const REACT_APP_CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN || 'localhost';
const REACT_APP_CLIENT_PORT = process.env.REACT_APP_CLIENT_PORT ? Number(process.env.REACT_APP_CLIENT_PORT) : undefined;

// Função para incluir porta condicionalmente
const buildHostname = (domain: string, port?: number, path?: string | null) => {
  const protocol = IS_PRODUCTION ? 'https' : 'http';
  const portPart = (port && !IS_PRODUCTION) ? `:${port}` : '';
  const pathPart = path ? `/${path}` : '';
  return `${protocol}://${domain}${portPart}${pathPart}`;
};

export const SERVER = {
  DOMAIN: REACT_APP_SERVER_DOMAIN,
  PORT: REACT_APP_SERVER_PORT,
  HOSTNAME: buildHostname(REACT_APP_SERVER_DOMAIN, REACT_APP_SERVER_PORT)
};

export const CLIENT = {
  DOMAIN: REACT_APP_CLIENT_DOMAIN,
  PORT: REACT_APP_CLIENT_PORT,
  HOSTNAME: buildHostname(REACT_APP_CLIENT_DOMAIN, REACT_APP_CLIENT_PORT)
};

export const GOOGLE = {
  storage: {
    bucket: process.env.GOOGLE_STORAGE_BUCKET || 'default-bucket',
    baseUrl: 'https://storage.googleapis.com',
    publicUrl: `https://storage.googleapis.com/${process.env.GOOGLE_STORAGE_BUCKET || 'default-bucket'}`,
    limitFileSize: 1024 * 1024 * 20 // 20 MB
  },
  gmail: {
    email: process.env.GOOGLE_GMAIL_EMAIL || '',
    name: process.env.GOOGLE_GMAIL_NAME || ''
  }
};

export const MAINTENANCE = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
