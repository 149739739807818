import { styled } from "styled-components";

export const Container = styled.div`
	width: 100%;
	max-width: 1320px;
	margin: 0 auto;
	padding: 0 40px;
	
	@media (max-width: 1400px) {
		max-width: 1200px;
	}

	@media (max-width: 576px) {
		padding: 0 20px;
	}
`;

export const Button = styled.button<{ min?: boolean, background?: string, boxShadow?: string, whatsapp?: boolean, radius?: string, disabled?: boolean, loading?: boolean }>`
	padding: 18px 40px;
	max-width: max-content;
	border: none;
	background: ${({ background }) => background || "var(--color-orange)"};
	box-shadow: 0 0 40px ${({ boxShadow }) => boxShadow || "#ec910c60"};
	border: 1px solid ${({ background }) => background || "var(--color-orange)"};
	color: #fff;
	font-size: 20px;
	font-weight: 700;
	letter-spacing: .5px;
	border-radius: ${({ radius }) => radius || "100px"};
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;

	user-select: none;
	-o-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;

	cursor: pointer;
	transition: all .2s ease-in-out;

	position: relative;
	overflow: hidden;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255,255,255,.14);
		opacity: 0;
	}

	&:hover {
		&:after {
			opacity: 1;
		}
	}

	&.gray {
		background: rgba(0,0,0,.3);

		&:hover {
			background: rgba(0,0,0,.5);
		}
	}

	&.maxWidth {
		width: 100%;
		max-width: 100%;
	}

	svg {
		min-width: 25px;
		max-width: 25px;
		margin: -4px 20px -4px -4px;
	}

	${({ min }) => min && `
		font-size: 16px;
		padding: 18px 30px;
	`};

	${({ whatsapp }) => whatsapp && `
		background: var(--color-whatsapp);
		border-color: var(--color-whatsapp);
		box-shadow: 0 0 20px rgba(0,255,0,.3);

		&:hover {
			background: #17C257;
			border-color: #17C257;
		}
	`};

	${({ loading }) => loading && `
		color: transparent;
		position: relative;
		opacity: .8;
		cursor: progress;
		transition: 200ms ease-in-out 200ms, color 0s;

		&::before {
			content: "";
			width: 24px;
			height: 24px;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			border-radius: 100%;
			background: transparent;
			border: 6px solid #FFF;
			border-right-color: transparent;
			animation: 1s AnimationLoading infinite;

			transition: 200ms ease-in-out 200ms;

			@keyframes AnimationLoading {
				0% {
					transform: rotate(0);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}
	`};

	${({ disabled }) => disabled && `
		opacity: .6;
		cursor: not-allowed;
	`};

	@media (max-width: 576px) {
		padding: 18px 30px;
		font-size: 1rem;
		line-height: 1;
	}

`;

export const CategoryTitle = styled.div`
	font-size: 22px;
	font-weight: 800;
	margin: 50px 0 20px;
	position: relative;

	display: flex;
	align-items: center;
	--text-divider-gap: 1.2rem;

	&::after {
		content: "";
		height: 1px;
		background-color: var(--color-border-secondary);
		flex-grow: 1;
		margin-top: 2px;
		margin-left: var(--text-divider-gap);
	}

	@media (max-width: 576px) {
		font-size: 20px;
	}
`;