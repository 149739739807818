import { Context, createContext, useContext, useEffect, useState } from "react";
import { useSocket } from "./SocketContext";
import { IMessage } from "src/core/interfaces/IMessage";
import AuthenticationSection from "../components/Authentication";
import { ApplicationContext } from "./ApplicationContext";
import actions from "../actions";

type ContextTypes = {
	stats: any;
}

export interface IChat
{
	_id: string;
	name: string;
	online: boolean;
	lastActive: Date;
	messages: IMessage[]; // SEMPRE A MENSAGEM MAIS RECENTE NO PRIMEIRO INDEX
}

export const AdminContext: Context<ContextTypes> = createContext({} as ContextTypes);

export function AdminProvider({ children }: any)
{
	const [stats, setStats] = useState<any>(null);
	
	async function getChats()
	{
		await actions.api.get("/channels").then(response => {
			setChats(response.data)
			// console.log(response.data)
		});
	}

	async function onStats()
	{
		// const data = await StatsActions.GetStats();
		// if (data) setStats(JSON.parse(data));
	}
	
	const { user} = useContext(ApplicationContext);

	const { socket, setMessages, setChats, chatSelected, setChatSelected } = useSocket();
	
	useEffect(() => {

		if (!user) return;

		getChats();
		onStats();
		
		if (socket) {

			socket.onmessage = (event) => {

				const dataFromServer = JSON.parse(event.data);
				let message = dataFromServer.message as IMessage;
				let channel = dataFromServer.channel as IChat;

				switch (dataFromServer.type) {
					
					case "new-message":

						// if (message.sender._id !== user._id && !message.delivered)
						// {
						// 	// Enviar confirmação de entrega
						// 	message.delivered = new Date();
						// 	socket.send(JSON.stringify({ type: "delivered-message", messageId: message._id, deliveredAt: message.delivered }));
						// }
						
						setChats((prevState: IChat[] | null) => {
							
							var channel = prevState?.filter(x => x._id === message.channel._id)[0] as IChat;

							if (channel)
							{
								channel.messages = [ ...(channel.messages || []), message ];
							}
							else
							{
								channel = message.channel;
							}

							const otherChats = prevState?.filter(x => x._id !== message.channel._id) as IChat[];

							return [ channel, ...(otherChats || []) ]
						});
						
						setMessages((prevMessages: IMessage[] | null) => {
							const channelSelected = prevMessages && prevMessages[0].channel._id;
							if (channelSelected === message.channel._id) {
								return [ ...(prevMessages || []), message ];
							}
							else {
								return prevMessages;
							}
						});

						break;

					case "channel-updates":
						setChats((prevChats: IChat[] | null) => {
								
							// Fazendo uma cópia do estado anterior para garantir imutabilidade
							const updatedChats = prevChats ? [...prevChats] : [];

							// Encontrar o índice do chat que precisa ser atualizado
							const chatIndex = updatedChats.findIndex(chat => chat._id === channel._id);

							// Verificar se o chat foi encontrado
							if (chatIndex !== -1) {
								// Atualizando o chat específico com novos valores
								updatedChats[chatIndex] = {
									...updatedChats[chatIndex],
									name: channel.name,
									lastActive: channel.lastActive,
									online: channel.online
								};
							}

							// Retorna o novo estado com o chat atualizado
							return updatedChats;
						});

						if (chatSelected?._id === channel._id)
						{
							setChatSelected((prevState: IChat | null) => {

								if (!prevState) return null;

								prevState.name = channel.name;
								prevState.online = channel.online;
								prevState.lastActive = channel.lastActive;

								return prevState;
							})
						}
						
						break;
				}

			};
		}
	}, [user]);
	
	const contextFunctions: ContextTypes = {
		stats
	}

	return <AdminContext.Provider value={contextFunctions}>
		{!user ? <AuthenticationSection /> : children}
	</AdminContext.Provider>
}