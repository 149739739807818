import styled from "styled-components";

export const WrapperConfiguration = styled.div`
  display: flex;
  margin: 40px 0 0;

  @media (max-width: 991px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const Menu = styled.div<{ mobileActive?: boolean }>`
  width: 100%;
  max-width: 300px;

  .arrow {
    margin: 10px 5px;
    cursor: pointer;
    transition: .1s ease-in-out;
    
    display: none;
    align-items: center;
    font-size: 20px;
    font-weight: 800;
    
    > svg {
      height: 50px;
      min-width: 50px;
      max-width: 50px;
      fill: black;
      padding: 15px;
      opacity: .6;
      transition: .1s ease-in-out;
  
      :hover {
        opacity: .8;
      }
    }
  }

  @media (max-width: 991px) {
    .arrow {
      display: flex;
    }

    position: absolute;
    background: white;
    z-index: 999999;
    overflow: hidden;
    transition: .1s ease-in-out;
    
    max-width: ${props => props.mobileActive ? '100vw' : '0'};
    left: 0;
    top: var(--header-size);
    height: calc(100vh - var(--header-size));

    > * {
      opacity: ${props => props.mobileActive ? '1' : '0'};
      transition: .1s ease-in-out .2s;
    }
  }

  @media (max-width: 576px) {
    top: 0;
    height: 100vh;
  }
`;

export const MenuItem = styled.div<{ $active?: boolean, background?: string, backgroundHover?: string, color?: string }>`
  width: 100%;
  cursor: pointer;
  
  padding: 14px 16px;
  border-radius: 8px;

  display: flex;
  align-items: center;

  font-size: 16px;
  font-weight: ${props => props.$active ? '700' : '500'};

  transition: .1s ease-in-out;

  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  :hover {
    background: var(--color-hover);
  }

  > svg {
    height: 20px;
    width: 20px;
    margin: 0 15px 0 0;
    fill: var(--color);
    opacity: ${props => props.$active ? '1' : '.5'};
    transition: .1s ease-in-out;
  }

  ${props => props.color === "white" && `color: white; :hover { color: white; > svg { fill: white; } } > svg { fill: white; }`};

  @media (max-width: 991px) {
    padding: 20px;
    border-radius: 0;
    font-weight: 800;

    > svg {
      opacity: .6;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 0 0 60px;
  border-left: 1px solid var(--color-border);

  @media (max-width: 991px) {
    border: none;
    padding: 40px 0;
  }
`;

export const WrapperSection = styled.div<{ maxWidth?: number }>`
  width: 100%;
  max-width: ${props => `${props.maxWidth}px` || "100%"};
`;

export const WrapperItem = styled.div<{ maxWidth?: number }>`
  margin: 0 0 40px;
  padding: 0 0 20px;
  border-bottom: 1px solid var(--color-border);

  &:last-child {
    border: none;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
`;

export const WrapperTitle = styled.div`
  padding: 0 0 20px;

  a {
    color: var(--color-primary);
    font-weight: 800;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
  
  h1 {
    font-size: 40px;
    font-weight: 800;
    border-bottom: 1px solid var(--color-border);
    padding: 0 0 30px;
    line-height: 1.2;
  }

  h2 {
    font-size: 26px;
    font-weight: 800;
  }

  p {
    padding: 10px 0 0;

    span {
      font-weight: 800;
    }

    svg {
      height: 16px;
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 800;

    span {
      font-weight: 800;
    }
  }

  .edit-icon {
    height: 40px;
    width: 40px;
    padding: 8px;
    border-radius: 8px;
    margin-top: -10px;

    fill: rgba(0,0,0,.8);
    cursor: pointer;

    :hover {
      background: ${props => props.theme?.colors?.gray};
    }
  }

  @media (max-width: 991px) {
    h1 {
      font-size: 30px;
    }

    h2 {
      font-size: 20px;
    }

    &.no-991 {
      display: none;
    }
  }
`;

export const ItemEmpty = styled.div`
  padding: 20px;
  border-radius: 8px;
  border: 2px dashed;
  text-align: center;
  margin: 20px 0;

  font-weight: 800;

  color: rgba(0,0,0,.4);
`;

export const Headline = styled.div<{ marginTop?: number, marginBottom?: number }>`
  display: flex;
  flex-direction: column;
  margin: 60px 0;
  margin-top: ${props => `${props.marginTop || 0}px`};
  margin-bottom: ${props => `${props.marginBottom || 0}px`};

  h1 {
    font-size: 28px;
    font-weight: 800;
    margin: 0 0 4px;
  }
  
  h2 {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 4px;
  }
  
  p {
    font-size: 16px;
    font-weight: 400;
    margin: 10px auto 0;

    span {
      font-weight: 800;

      &.orange {
        color: var(--color-primary);
      }
    }
  }
  
  @media (max-width: 480px) {
    margin: 30px 0;

    h1 {
      font-size: 22px;
      font-weight: 800;
    }
    p {
      font-size: 14px;
      max-width: 380px;
      max-width: 300px;
    }
  }
`;

export const Text = styled.div`
  h1 {
    font-size: 40px;
    font-weight: 400;
  }

  p {
    font-size: 20px;
  }
`;
