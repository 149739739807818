import { useState } from "react";

import { formatFullNameAbbr } from "src/core/helpers/format";
import { getUserInitials } from "src/core/helpers/user";
import { ProfileWrapper, MenuOptions } from "./styles";

import { ReactComponent as UserSvg } from "src/client/assets/icons/user.svg";
import { ReactComponent as ConfigsSvg } from "src/client/assets/icons/gear.svg";
import { ReactComponent as LogoutSvg } from "src/client/assets/icons/logout.svg";
import useRouter from "src/client/hooks/useRouter";
import usePathname from "src/client/hooks/usePathname";
import { EnumUserRole } from "src/core/enums/EnumUser";

const Profile = ({ user, isAdmin, Logout, closeMenuMobile, redirect }: any) => {

	const [menuOpened, setMenuOpened] = useState(false);

	const router = useRouter();
	const pathname = usePathname();

	return <ProfileWrapper $active={menuOpened ? 1 : 0} onClick={() => {
		closeMenuMobile();
		setMenuOpened(!menuOpened);
	}}>
		<div className="content">
			<div className="info">
				<h4>{formatFullNameAbbr(user?.name)}</h4>
			</div>
			<div className="initials" style={{ backgroundImage: `url(${user?.image})` }}>{getUserInitials(user?.name)}</div>
		</div>

		<MenuOptions $active={menuOpened ? 1 : 0}>
			{ user.role === EnumUserRole.Admin ? <>
				<div className="option" onClick={() => {
					const pathTarget = "/admin/configs";
					if(pathname !== pathTarget) redirect(() => router(pathTarget), true)
				}}>
					<ConfigsSvg />
					<span>Configurações</span>
				</div>

				<div className="separator" />
			</> : <></> }
			
			<div className="option" onClick={() => {
				const pathTarget = `/${isAdmin ? "admin" : "minha-conta"}/perfil`;
				if(pathname !== pathTarget) redirect(() => router(pathTarget), true)
			}}>
				<UserSvg />
				<span>Meu perfil</span>
			</div>

			<div className="separator" />

			<div className="option" onClick={() => redirect(Logout, false)}>
				<LogoutSvg />
				<span>Desconectar</span>
			</div>
		</MenuOptions>
	</ProfileWrapper>
}

export default Profile;