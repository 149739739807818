import { useContext } from "react";

import WhatsAppButton from "src/client/components/Layout/WhatsAppButton";
import ExamesAudiologicosSectionOne from "src/client/sections/institutional/exames-audiologicos/one";
import ExamesAudiologicosList from "src/client/sections/institutional/exames-audiologicos/ExamesAudiologicosList";
import StripeReinforcement from "src/client/sections/institutional/StripeReinforcement";
import FormSection from "src/client/sections/institutional/Form";
import Footer from "src/client/components/Layout/Footer";
import { ApplicationContext } from "src/client/contexts/ApplicationContext";
import Testimonials from "src/client/sections/institutional/Testimonials";
import { WrapperInstitutional } from "src/client/styles/admin";

export default function ExamesAudiologicosInstitutionalPage() {

  const { scroll, setScroll } = useContext(ApplicationContext);

  return (
    <>
      <WhatsAppButton />

      <WrapperInstitutional $scroll={scroll} id="WrapperInstitutional" onScroll={(e: any) => setScroll(e.target.scrollTop)}>
        <ExamesAudiologicosSectionOne />
        <ExamesAudiologicosList />
        <Testimonials />
        <StripeReinforcement />
        <FormSection />
        <Footer />
      </WrapperInstitutional>
    </>
  )
}